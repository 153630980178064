// ----------------------------------------------------------------------


const ROOTS = {
  LANDING: `${process.env.NEXT_PUBLIC_HOST_WEBSITE}/`,
  AUTH: `${process.env.NEXT_PUBLIC_HOST_WEBSITE}/auth`,
  DASHBOARD: `${process.env.NEXT_PUBLIC_HOST_WEBSITE}/dashboard`,
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  landing: {
    root: ROOTS.LANDING,
    breweries: `${ROOTS.LANDING}/for-breweries`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
    },
  },
};
